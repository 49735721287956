// Generated by Framer (2d506a1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-fFVcp"

const variantClassNames = {qfUqcYsb5: "framer-v-8gheaz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translateX(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, image, link, text, width, ...props}) => { return {...props, aurblqCvl: text ?? props.aurblqCvl ?? "Ainda na dúvida sobreinvestir em CRM?", BZ6hGtQQi: link ?? props.BZ6hGtQQi, waGP0Ncyc: image ?? props.waGP0Ncyc ?? {src: "https://framerusercontent.com/images/v0jpiptb9EM7HVTrPt6j6j6Ifs.png"}} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string;image?: {src: string; srcSet?: string};link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, aurblqCvl, waGP0Ncyc, BZ6hGtQQi, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "qfUqcYsb5", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={BZ6hGtQQi} nodeId={"qfUqcYsb5"} openInNewTab><Image {...restProps} {...gestureHandlers} as={"a"} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 500, pixelWidth: 310, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(waGP0Ncyc), ...{ positionX: "center", positionY: "center" }}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-8gheaz", className, classNames)} framer-yh0hv`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"qfUqcYsb5"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXIgVGlnaHQtNjAw", "--framer-font-family": "\"Inter Tight\", \"Inter Tight Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "600", "--framer-line-height": "120%", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(0, 80, 138))"}}>Ainda na dúvida sobre<motion.br/>investir em CRM?</motion.p></React.Fragment>} className={"framer-630qiq"} data-framer-name={"Provide important information that is actual, sharp and reliable"} fonts={["GF;Inter Tight-600"]} layoutDependency={layoutDependency} layoutId={"dIbZcuO_p"} style={{"--extracted-r6o4lv": "rgb(0, 80, 138)"}} text={aurblqCvl} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></Image></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fFVcp.framer-yh0hv, .framer-fFVcp .framer-yh0hv { display: block; }", ".framer-fFVcp.framer-8gheaz { height: 500px; overflow: visible; position: relative; text-decoration: none; width: 310px; }", ".framer-fFVcp .framer-630qiq { flex: none; height: auto; left: 50%; position: absolute; top: 60px; white-space: pre-wrap; width: 260px; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 500
 * @framerIntrinsicWidth 310
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"aurblqCvl":"text","waGP0Ncyc":"image","BZ6hGtQQi":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerUB8HdGbuf: React.ComponentType<Props> = withCSS(Component, css, "framer-fFVcp") as typeof Component;
export default FramerUB8HdGbuf;

FramerUB8HdGbuf.displayName = "Banners/Banner-3";

FramerUB8HdGbuf.defaultProps = {height: 500, width: 310};

addPropertyControls(FramerUB8HdGbuf, {aurblqCvl: {defaultValue: "Ainda na dúvida sobreinvestir em CRM?", displayTextArea: true, title: "Text", type: ControlType.String}, waGP0Ncyc: {__defaultAssetReference: "data:framer/asset-reference,v0jpiptb9EM7HVTrPt6j6j6Ifs.png?originalFilename=Banner-3.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, BZ6hGtQQi: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerUB8HdGbuf, [{explicitInter: true, fonts: [{family: "Inter Tight", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/intertight/v7/NGSnv5HMAFg6IuGlBNMjxJEL2VmU3NS7Z2mj0QiqWSRToK8EPg.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})